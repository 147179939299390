<script lang="ts" setup>
import dayjs from "dayjs";

const props = defineProps<{
	prize: number;
	title?: string;
	finishedAt?: string;
}>();
defineEmits<{ (event: "click"): void }>();

const prepareImgUrl = useImage();
const { t } = useT();
const { durationLeft, durationExceeded, reset } = useCountdown({
	timestamp: props.finishedAt || "",
	formatToken: dayjs(props.finishedAt).diff(dayjs(), "d") >= 1 ? "DD[d ]HH[h ]mm[m ]" : "HH[h ]mm[m ]ss[s ]"
});

watch(
	() => props.finishedAt,
	() => {
		reset(props.finishedAt || "");
	}
);
</script>
<template>
	<div class="activity-season" @click="$emit('click')">
		<picture>
			<source
				:srcset="
					prepareImgUrl('/nuxt-img/advent/banner-mob.webp', {
						format: 'avif',
						width: 374,
						height: 476,
						loading: 'lazy'
					})
				"
				media="(max-width: 767px)"
			/>
			<NuxtImg
				class="image"
				src="/nuxt-img/advent/banner.webp"
				format="avif"
				width="416"
				height="340"
				alt="advent"
				loading="lazy"
			/>
		</picture>
		<ABadge variant="skew" background="var(--cairns)" autosize>
			<AText class="text-cannes" variant="topeka" :modifiers="['bold', 'uppercase']">
				{{ title }}
			</AText>
		</ABadge>

		<div class="content">
			<div class="prize-pool">
				<AText class="label text-cannes" variant="topeka" :modifiers="['bold', 'uppercase']">
					{{ t("Collect") }}
				</AText>
				<MPrizeFund variant="entries" :iconSize="24" img="/nuxt-img/cards/entries.png">
					<AText variant="tanta" :modifiers="['bold']">
						{{ numberFormat(prize) }}
					</AText>
				</MPrizeFund>
			</div>
			<AText v-if="!durationExceeded" class="text-conakry">
				{{ t("Ends in") }}&nbsp;
				<b class="text-cannes">{{ durationLeft }}</b>
			</AText>
			<AButton variant="primary">
				<AText variant="toledo" :modifiers="['uppercase']">
					{{ t("Open") }}
				</AText>
			</AButton>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.activity-season.item {
	padding-top: 42px;
	background: var(--goma);

	@include media-breakpoint-down(md) {
		padding: 112px 0 12px;
	}

	.content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include media-breakpoint-down(md) {
			align-items: center;
		}
	}

	.image {
		position: absolute;
		right: 0;
		top: 0;
		width: 208px;
		height: 170px;

		@include media-breakpoint-down(md) {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.badge {
		position: absolute;
		left: 0;
		top: 0;
	}

	button {
		min-width: 165px;
		height: 36px;
		width: max-content;
		gap: 12px;

		.nuxt-icon {
			display: inline-flex;
			font-size: 27px;

			:deep(svg) {
				margin-bottom: 0;
			}
		}

		@include media-breakpoint-down(md) {
			min-width: 135px;
			max-width: 100%;

			> span {
				font-size: 13px;
			}

			.nuxt-icon {
				font-size: 24px;
			}
		}
	}

	.prize-pool {
		--m-fund-entries-color: var(--cixi);
		display: flex;
		align-items: center;
		z-index: 1;
		gap: 8px;

		.prize {
			gap: 4px;
		}

		@include media-breakpoint-down(md) {
			flex-wrap: wrap;
			justify-content: center;
			gap: 4px;

			span.tanta {
				font-size: 16px;
			}

			&:deep(img) {
				width: 20px;
				height: 20px;
			}
		}

		.label {
			max-width: 55px;

			@include media-breakpoint-down(md) {
				max-width: none;
				width: 100%;
				text-align: center;
			}
		}
	}
}
</style>
